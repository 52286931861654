.faq-section{
    width: 100%;
    padding-top: 100px;
    padding-bottom: 50px;
    background: #141718;
    overflow: hidden;
}



.faq-dropdown-container{
    width: 100%;
    max-width: 1500px;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    justify-content: center;
    gap: 20px;
    margin: auto;
}



.faq-dropdown{
    width: 93%;
    height: 70px;
    padding-left: 5%;
    padding-right: 5%;
    display: flex;
    justify-content: space-between;
    background-color: white;
    border: 3px solid #41494E;
    cursor: pointer;
}



.faq-dropdown-question{


font-family: 'Roboto Slab';
font-style: normal;
font-weight: 400;
font-size: 20px;
line-height: 135%;
letter-spacing: 1px;

color: #000000;

}



.faq-dropdown-arrow{
    width: 20px;
    height: 20px;
    margin-top: auto;
    margin-bottom: auto;
    border-right: 5px solid #000000;
    border-bottom: 5px solid #000000;
    transform: rotate(45deg);

}