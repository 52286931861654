

/* upper section of about us page */


/* Parent Container */
.upper-container{
    display: flex;
    max-width: 1500px;
    max-height: 900px;
    margin: auto;
    justify-content: space-between; 
    align-items: center;
    height: 70vh; 
    padding: 20px;
    gap: 20px; 
    overflow: hidden;
    

}

/* Left Section */
.upper-left-section {
    display: flex;
    width: 40%;
    margin: auto;
    flex-direction: column; /* Stack vertically */
    gap: 20px; /* Space between the two divs */
    background-color: blue;
    

}

.upper-text-box {
    position: absolute;
    width: 45%; /* Set a fixed or responsive width */
    max-width: 800px;
    height: 150px; /* Set a fixed height */
    transform: translateY(-100%);
    background-color: #D9D9D9; /* Example background */
    color: #000000;
    font-family: 'Playfair Display SC';
    font-style: normal;
    font-weight: 700;
    font-size: 40px;
    line-height: 100%;
    display: flex;
    justify-content: center;
    align-items: center; /* Center the text */
    text-align: center;
    text-transform: uppercase;
    letter-spacing: 1px;
    z-index: 3;
    
}




.upper-text-box1{
    position: absolute;
    width: 30%;
    max-width: 500px;
    height: 105px;
    margin-top: 50px;
    font-family: 'Roboto Slab';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 135%;
    display: flex;
    align-items: center;
    letter-spacing: 1px;
    color: #000000;
}



/* Right Section */
.upper-right-section {
    position: relative; /* Position relative for stacking */
    width: 60%;
    margin: auto; /* Fixed width */
    height: 100%; 
    // background-color: blue;
    
}

.upper-grey-box {
    margin: auto;
    width: 80%; /* Full width */
    height: 100%; /* Smaller top/bottom, wider left/right */
    background: rgba(164, 164, 164, 0.47);
}




.upper-top-image {
    position: absolute;
    bottom: 10%; /* Place just above the grey rectangle */
    width: 100%; /* Narrow width */
    height: 80%; /* Taller height */
    left: 50%; /* Center horizontally */
    transform: translateX(-50%);
}



/* lower section of about us page */

.lower-container{
    display: flex;
    max-width: 1500px;
    max-height: 900px;
    margin: auto;
    justify-content: space-between; 
    align-items: center;
    height: 70vh; 
    padding: 20px;
    gap: 20px; 
    overflow: hidden;
    

}

/* Left Section */
.lower-right-section {
    display: flex;
    width: 40%;
    height: 300px;
    flex-direction: column; /* Stack vertically */

}

.lower-text-box {
    width: 100%; 
    height: 150px; 
    color: #000000;
    font-family: 'Playfair Display SC';
    font-style: normal;
    font-weight: 700;
    font-size: 40px;
    line-height: 100%;
    display: flex;
    justify-content: center;
    align-items: center; 
    text-align: center;
    text-transform: uppercase;
    letter-spacing: 1px;
    z-index: 3;

}




.lower-text-box1{
    width: 80%;
    height: 105px;
    margin-left: auto;
    margin-right: auto;
    font-family: 'Roboto Slab';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 135%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    letter-spacing: 1px;
    color: #000000;

}



/* Right Section */
.lower-left-section {
    position: relative; 
    width: 60%;
    margin: auto; 
    height: 100%; 
    
    
}

.lower-brown-box {
    margin: auto;
    width: 80%; /* Full width */
    height: 100%; /* Smaller top/bottom, wider left/right */
    background: #612826;
}






.lower-top-image {
    position: absolute;
    bottom: 15%; /* Place just above the grey rectangle */
    width: 100%; /* Narrow width */
    height: 70%; /* Taller height */
    left: 50%; /* Center horizontally */
    transform: translateX(-50%);
}