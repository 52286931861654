.head-margin{
    width: 100%;
    height: 150px;
    background-color: #1479B8;
}

input{
    font-size: 20px;
}

.search{
    width: 100%;
    height: 100px;
    display: flex;
    padding-left: 10%;
    background-color: #1479B8;
    
}
.from{
    margin-top: auto;
    margin-bottom: auto;
    margin-right: 5px;
    border-radius: 5px;
    width: 25%;
    height: 90%;
}

.inputStyle{
    
    border-radius: 5px;
    width: 100%;
    height: 100%;
}

.test2{
    margin-top: 5px;
    position: absolute;
    z-index: 3;
    border-radius: 5px;
    background-color: white;
    width: 30%;
    height: 350px;
    overflow: hidden;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
.hide{
    display: none;
}


.to{
    margin-top: auto;
    margin-bottom: auto;
    margin-right: 5px;
    border-radius: 5px;
    width: 25%;
    height: 90%;

}
.date{
    margin-top: auto;
    margin-bottom: auto;
    margin-right: 5px;
    border-radius: 5px;
    width: 25%;
    height: 90%;
}


.react-datepicker-wrapper,
.react-datepicker__input-container,
.react-datepicker__input-container input {
    width: 100%;
    height: 100%;
    border-radius: 5px;
}

.react-datepicker__day {
    width: 60px !important;
    height: 40px !important;
    font-size: 2rem;
    margin-top: 5px !important;
    text-align: center !important;
    padding-top: 10px;
}

.react-datepicker__day-name {
    width: 60px !important;
    height: 30px !important;
    font-size: 2rem;
    margin-top: 20px !important;
    margin-bottom: 0px !important;
}

.react-datepicker__current-month{
    font-size: 2rem !important;
    margin-top: 5px !important;
}



.search-button{
    margin-top: auto;
    margin-bottom: auto;
    border-radius: 5px;
    width: 25%;
    height:90%
}
.filter{
    flex: 1;
    width: 30%;
    height: 300px;
}
.trucks{
    width: 70%;
}
.filter-truck{
    display: flex;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
}

.inputChoices{
    height: 70px;
    width: 95%;
    margin: 10px;
    padding: 5px;
    font-size: 15px;
    cursor: pointer;
    float: right;
    border-bottom: 1px solid #ebe4e4;
    background-color: white;
    
} 
.inputChoices:hover{
    background-color: #ebe4e4;
}

.mapicon{
    width: 30px;
    height: 30px;
    margin-right: 10px;
}


.button-43 {
  background-image: linear-gradient(-180deg, #37AEE2 0%, #1E96C8 100%);
  border-radius: .5rem;
  box-sizing: border-box;
  color: #FFFFFF;
  display: flex;
  font-size: 16px;
  justify-content: center;
  padding: 1rem 1.75rem;
  text-decoration: none;
  width: 10%;
  border: 0;
  cursor: pointer;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.button-43:hover {
  background-image: linear-gradient(-180deg, #1D95C9 0%, #17759C 100%);
}

@media (min-width: 768px) {
  .button-43 {
    padding: 1rem 2rem;
  }
}

@media screen and (max-width: 900px) {

    .search{
        display: block;
        height: 200px;
        background-color: #1479B8;
        padding-top: 1px;

    }

    .test2{
        width: 80%;
    }
    .to{
        margin-top: 2px;
        width: 90%;
        height: 22%;
    }
    .from{
        margin-top: 10px;
        width: 90%;
        height: 22%;
    }
    .date{
        margin-top: 2px;
        width: 90%;
        height: 22%;
    }
    .search-button{
        margin-top: 2px;
        width: 90%;
        height: 22%;
    }
    .filter-truck{
        display: block;
    }
    .trucks{
        width: 100%;
        margin-top: 25px;
    }
    .filter{
        height: 30px;
    }
  }