* {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: 'Orbitron' ,sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
 
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


a{
  text-decoration: none;
  color: black;
}

* {
  box-sizing: border-box;
}

::-webkit-scrollbar {
  height:5px;
  width: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  
  background: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: #808080;
  border: 1px solid #aaa;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #fff;
}

::-webkit-scrollbar-thumb:active {
  background: black;
}