@import url('https://fonts.googleapis.com/css2?family=Bowlby+One&family=Lora:ital,wght@0,400..700;1,400..700&family=Merriweather:ital,wght@0,300;0,400;0,700;0,900;1,300;1,400;1,700;1,900&display=swap');



.pricing-card-container{
    
    margin-top: auto;
    margin-bottom: auto;

}



.pricing-card-top{
    width: 100%;
    height:15%;
    background: #484D4E;
    border-radius: 10px 10px 0px 0px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    align-items: center;

    font-family: 'Lora';
    font-style: normal;
    font-weight: 700;
    font-size: 3rem;
    line-height: 100%;
    letter-spacing: 3px;
    color: #ffffff;

    padding-bottom: 5%;
    
}



.pricing-card-bot{
    width: 100%;
    height:85%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-content: center;
    align-items: center;
    padding: 5%;
    background-color: white;
    border-radius: 10px;
    margin-top: -15px;
    z-index: 1;
}


.pricing-card-bot-price{
    width: 70%;
    height: 15%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'lora';
    font-style: normal;
    font-weight: 500;
    font-size: 30px;
    line-height: 100%;
    color: #666666;
    border: 4px solid #666666;
    box-sizing: border-box;
}





.pricing-card-bot-list{
    width: 90%;
    height: 60%;
    font-family: 'lora';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;

    color: #666666;

}





.pricing-card-bot-button{
    width: 60%;
    height: 12%;
    display: flex;
    justify-content: center;
    align-items: center;

}







