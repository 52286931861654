
.navigation-container{
  display: flex;
  width: 100%;
  height: 100px;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  z-index: 4;
  backdrop-filter: blur(10px); 
  -webkit-backdrop-filter: blur(10px);
  mask: linear-gradient(to top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 20%, rgba(0, 0, 0, 1) 100%);
  -webkit-mask: linear-gradient(to top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 20%, rgba(0, 0, 0, 1) 100%);
}




.navigation {
  height: 65px;
  width: 100%;
  max-width: 1500px;
  display: flex;
  justify-content: space-between; 
  margin-bottom: 25px;
  position: fixed;
  top: 0;
  z-index: 5;
  

  .logo-container {
    height: 100%;
    width: 100px;
    margin-left: 20px;
    padding-top: 10px;
  }
  .logo {
    height: 50px;
    width: 100px;
  }

  .nav-links-container{
    width: 80%;
    display: flex;
    justify-content: space-between;
  }



  .nav-links-container-left {
    width: 50%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;



    .nav-link-left {
      padding: 0px 15px 0px 15px;
      cursor: pointer;
      font-size: 20px;
      background-color: transparent;
      color: black;

      &:hover{
        background-color:   #093D5D;
        color: white;
      }

    }
  }


  .nav-links-container-right {
    width: 400px;
    height: 100%;
    margin-right: 20px;
    display: flex;
    align-items: center;
    justify-content: right;

  

    .nav-link-right-button {
      align-items: center;
      margin: 0px 10px 0px 10px;
      appearance: none;
      border-radius: 24px;
      border-style: none;
      box-shadow: rgba(0, 0, 0, .2) 0 3px 5px -1px,rgba(0, 0, 0, .14) 0 6px 10px 0,rgba(0, 0, 0, .12) 0 1px 18px 0;
      box-sizing: border-box;
      cursor: pointer;
      font-size: 15px;
      display: inline-flex;
      fill: currentcolor;
      height: 48px;
      justify-content: center;
      letter-spacing: .25px;
      line-height: normal;
      max-width: 100%;
      overflow: visible;
      padding: 2px 24px;
      position: relative;
      text-align: center;
      text-transform: none;
      transition: box-shadow 280ms cubic-bezier(.4, 0, .2, 1),opacity 15ms linear 30ms,transform 270ms cubic-bezier(0, 0, .2, 1) 0ms;
      user-select: none;
      -webkit-user-select: none;
      touch-action: manipulation;
      width: 120px;
      will-change: transform,opacity;
      z-index: 0;
    }
    
    .nav-link-right-button:active {
      box-shadow: 0 4px 4px 0 rgb(60 64 67 / 30%), 0 8px 12px 6px rgb(60 64 67 / 15%);
      outline: none;
    }

    .white-underline{
      color: black;
    }
    .white-underline:after{
      background-color: black;
    }

    .black-underline{
      color: white;
    }
    .black-underline:after{
      background-color: black;
    }

    .nav-link-right-underline {
      padding: 0px 15px 0px 15px;
      font-size: 20px;
      display: inline-block;
      position: relative;
    }
    
    .nav-link-right-underline:after {
      content: '';
      position: absolute;
      width: 100%;
      transform: scaleX(0);
      height: 2px;
      bottom: 0;
      left: 0;
      transform-origin: bottom right;
      transition: transform 0.25s ease-out;
    }
    
    .nav-link-right-underline:hover:after {
      transform: scaleX(1);
      transform-origin: bottom left;
    }
  }
}

.nav-background{
  background-color: white;
  animation: slide-bottom 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;   
  
}


.burger{
  display: none;
  cursor: pointer;
}

.burger div{
  width:25px;
  height:3px;
  background-color:#000000;
  margin: 5px;
  transition: all 0.3s ease;
  
}

.burger-toggle-line1{
  transform: rotate(-40deg) translate(-5px,6px);
}

.burger-toggle-line2{
  transform: rotate(90deg) translate(0px,0px);
}

.burger-toggle-line3{
  transform: rotate(35deg) translate(-5px,-6px);
}


@keyframes slide-bottom {
  0% {
    -webkit-transform: translateY(-100px);
            transform: translateY(-100px);
  }
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
}

@media screen and (max-width:1100px){

  .navigation-container{
    display: flex;
    width: 100%;
    height: 100px;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    z-index: 4;

  }

  .navigation-container-active{
    backdrop-filter: blur(0px); 
    -webkit-backdrop-filter: blur(0px);
    background-color: rgba(191, 191, 191, 1);
    mask: none;
    -webkit-mask: none;
  }

  .burger{
    display:block;
    margin-top: 20px;
    margin-right: 20px;
  }
  .nav-links-container{
    width: 100vw !important;
    position: fixed;
    right:0px;
    height: 91vh;
    top: 100px;
    background-color:rgba(191, 191, 191, 1) ;
    flex-direction: column;  
    align-items: center !important;
    justify-content: center !important;
    z-index: 5;
  }

  .nav-links-container-hide{
    display: none;
  }

  .nav-links-container-left{
    width: 400px !important;
    height: 200px;
    display: block;
    flex-direction: column;  
    align-items: center;
  }

  .nav-links-container-right{
    flex-direction: column;  
    align-items: center;
    margin: 0 !important;
  }

}