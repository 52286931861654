

@import url('https://fonts.googleapis.com/css2?family=Bowlby+One&family=Merriweather:ital,wght@0,300;0,400;0,700;0,900;1,300;1,400;1,700;1,900&display=swap');



.pricing-page{
    width: 100%;
    height: 100vh;
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.42), rgba(255, 255, 255, 0.42)), #BFBFBF;
}



.pricing-headline{
    width: 100%;
    height: 30vh;
    max-width: 1500px;
    max-height: 600px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    margin: auto;

    .pricing-headline-title{

        font-family: 'Merriweather';
        font-style: normal;
        font-weight: 900;
        font-size: 64px;
        letter-spacing: 2px;

        color: #4B4B4B;
    }

    .pricing-headline-paragraph{

        font-family: 'Merriweather';
        font-style: normal;
        font-weight: 500;
        font-size: 29px;
        letter-spacing: 2px;

        color: #515151;
    }

    .pricing-headline-line{
        width: 20%;
        border-bottom: 3px solid #A9A7A7;
    }

}


.pricing-cards{
    width: 100%;
    height: 50vh;
    max-width: 1500px;
    max-height: 900px;
    display: flex;
    gap: 10px;
    justify-content: center;
    margin: auto;

}

.pricing-footing{
    width: 100%;
    height: 20vh;
    max-width: 1500px;
    max-height: 600px;
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}


.pricing-footing-top{
    width: 50%;
    height: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: 'Lora';
    font-style: normal;
    font-size: 12px;
    color: #515151;
}





.pricing-footing-bot{
    width: 50%;
    height: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 50px;
    font-family: 'Roboto Slab';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 135%;
    letter-spacing: 1px;
    color: #515151;
}


.pricing-footing-bot-seperation{
    height: 50%;
    border: 1px solid #5151516e;
}
