.wrap-filter{
    max-width: 40rem;
    height: auto;
    border: 1px solid #e7e7e7;
    border-radius: 5px;
}

.money-filter{
    width: 100%;
    height: 200px;
    padding-left: 5px;
    padding-top: 1px;
    border-top: 1px solid #e7e7e7;
    border-bottom: 1px solid #e7e7e7;
}

.range-wrapper{
    height: 5px;
    position: relative;
    width: 90%;
    margin-top: 50px;
    margin-left: auto;
    margin-right: auto;

}

.range-wrapper input{
    position: absolute;
    width: 100%;
    background: none;
    pointer-events: none;
    top: 50%;
    transform: translateY(-50%);
    appearance: none;
}



input[type="range"]::-webkit-slider-thumb  {
    height: 20px;
    width: 20px;
    border-radius: 50%;
    background: #006CE4;
    pointer-events: auto;
    appearance: none;
    -webkit-appearance: none;
    cursor: pointer;
    motion-path: none;

}

input[type="range"]::-moz-slider-thumb  {
    height: 20px;
    width: 20px;
    border-radius: 50%;
    background: #006CE4;
    pointer-events: auto;
    appearance: none;
    -webkit-appearance: none;
    cursor: pointer;
    motion-path: none;
    
}


.range-wrapper .progress{
    height: 0.4rem;
    border-radius: 3px;
    background: #006CE4;
    top: 50%;
    transform: translateY(-30%);
    position: absolute;
    z-index: 0;
    
}

.type-filter{
    margin-bottom: 10px;
}


.type-filter input{
    height: 20px;
    width: 20px;
    margin: 8px;
    cursor: pointer;
}
.filtering-icon{
    padding-top: 5px;
    font-size: 30px;
    height: 50px;
    width: 100%;
    display: none;

}
.filter-icon-size{
    width: 25px;
    margin-left: 10%;
}







@media screen and (max-width: 900px){
    .positioning{
        position: absolute;
        width: 100%;
        left: 0;
        z-index: 1;
    }

    .wrap-filter{
        display: block;
        left: 0;
        width: 100%;
        max-width: 100vw;
        margin-left: none;
        z-index: 0 ;
        background-color: white !important;
        overflow: hidden;
    }
    .filtering-icon{
        display: block;
        background-color: white;
        left: 0;
        overflow-x: hidden;
        z-index: 0;
    
    }

    .slide-up {
        max-height: 0;
        transition: max-height 1s ease;
      }
      
      .slide-down {
        max-height: 900px; 
        transition: max-height 1s ease;
      }
}
