@import url('https://fonts.googleapis.com/css2?family=Bowlby+One&family=Lora:ital,wght@0,400..700;1,400..700&family=Merriweather:ital,wght@0,300;0,400;0,700;0,900;1,300;1,400;1,700;1,900&family=Roboto+Slab:wght@100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Bowlby+One&family=Lora:ital,wght@0,400..700;1,400..700&family=Merriweather:ital,wght@0,300;0,400;0,700;0,900;1,300;1,400;1,700;1,900&display=swap');



.button-container {
    min-width: 70px;
    width: 100%;
    height: 100%;
    letter-spacing: 0.5px;
    font-size: 15px;
    background-color: #666666;
    color: white;
    -webkit-user-select: none; 
    -ms-user-select: none; 
    user-select: none; 
    font-family: 'Roboto Slab';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 100%;
    border: none;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 9px;
  
    &:hover {
      background-color: white;
      color: black;
      border: 1px solid #666666;
    }
  
    &.google-sign-in {
      background-color: #4285f4;
      color: white;
  
      &:hover {
        background-color: #357ae8;
        border: none;
      }
    }
  
    &.inverted {
      background-color: white;
      color: black;
      border: 1px solid #666666;
  
      &:hover {
        background-color: #666666;
        color: white;
        border: none;
      }
    }
  }