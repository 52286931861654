.benefits-section{
    width: 80%;
    max-width: 1100px;
    height: 450px;
    margin-top: 50px;
    margin-left: auto;
    margin-right: auto;
    display: flex;             
    flex-direction: column;    
    justify-content: center;    
    align-items: center;         
    text-align: center;   
    box-shadow: rgba(0, 0, 0, 0.24)  0px 3px 8px 3px;
    border-radius: 10px;
    box-sizing: border-box;
}




.benefit-container{
    width: 80%;
    height: 100px;
    display: flex;           
    justify-content: center;    
    align-items: center;         
    text-align: center;   
    position: relative;
    // background-color: green;
    
}

.benefit-container::after{
    content: '';
    display: block;
    width: 80%;
    height: 100%;
    bottom: 0;
    border-bottom: 1px solid #BBBBBB;
    // background-color: red;


}



.benefit-png{

    width: 51px;
    height: 51px;
    border-radius: 9px;
    position: absolute;
    left: 100px;
    flex-shrink: 0;               
    
}

.benefit-line{

    position: absolute;
    width: 66px;
    height: 0px;
    left: 150px;
    border: 1px solid #BBBBBB;
    transform: rotate(90deg);

}



.benefit{
    width: 70%;
    font-family: 'Merriweather';
    font-style: normal;
    font-weight: 700;
    font-size: 26px;
    line-height: 149%;
    letter-spacing: 2px;
    color: #000000;
    position: absolute;
    right: 10px;

}


@media only screen and (max-width: 1100px) {
    .benefits-section{
        height: auto;
    }

    .benefit-container{

        height: 200px;

    }

    .benefit-png{

        top: 10px;
        left: 48%;

    }

    .benefit-line{

        display: none;

    }

    .benefit{

        top:80px;
        left: 20%;

    }

}