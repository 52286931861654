.sorting-wrap{
    width: fit-content;
}

.sortTrucks{
    margin-top: 40px;
    padding: 5px;
    width: fit-content;
    display: flex;
    border-radius: 50px;
    border-style: solid;
    border-width: 1px;
    border-color: #37AEE2;
    cursor: pointer;
}

.filter-icon{
    width: 30px;
    height: 30px;
    margin-top: 5px;  
}

.arrow-icon{
    width: 20px;
    height: 20px;
    margin-top: 13px;  
    margin-left: 5px;
}

.sorting-options{
    width: 300px;
    height: fit-content;
    background-color: white;
    position: absolute;
    z-index: 1;
}

.options{
    font-size: 20px;
}


.hidden{
    visibility: hidden;
   
}

.visible{
    visibility: visible;
    transform: translate(20px,10px);
}


.noselect{
    -webkit-user-select: none;
}

