*{
    font-family:Arial, Helvetica, sans-serif;
    box-sizing: border-box;

}

html {
    font-size: 62.5%;
   }

.card-container{
    // background-color: rgb(142, 38, 38);
    height: 25rem;
    margin-top: 50px;
    margin:5 rem;
    width: 100%;
    align-self: center;
    border-radius: 1.5rem;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;    
  
}

.card-header{
    // background-color: #ff351f;
    width: 90%;
    display: flex;
    justify-content:space-between;
    height: 20%;
     font-size: 3rem;
    color: rgb(17, 0, 75);
    text-align: center;
    padding-top: 10px;
    margin: auto;


}

.type{
  // line-height: 5rem;
  color: black;
  height: 100%;
  // width:70%;  
    // display: flex;
    color: #393232;
    font-size: 2rem;
    // background-color: blue;
    text-align: right;

}




// h1{
//     display: flex;
//     align-items: center;
//     height:.5rem;
//     font-size: 2rem;

//     background-color: green;
   


// }

.card-description{


    display:flex;
    flex-wrap: wrap;
    // margin-left: 3.5rem;
    width: 90%;
    align-items: flex-start;
    margin-top: 0px;
    font-size: 1.5rem;
    height: 45%;
    transform: translate(2%,15%);
    // background-color: chartreuse;
}

.card-price{

    display: flex;
    justify-content:center;
    line-height: 0px;
    color: black;
    height: 3rem;
    width:10rem;
    transform: translate(2%,25%);
  //  background-color: cyan;

}

.card-location{

 
    display: flex;
    color: black;
    height: 3rem;
    width:20rem;
    line-height: 3.5rem;
    transform: translate(10%,25%);
    // background-color: darkblue;
}

.card-details{
  padding-left: 15px;
}


.card-button-test {
  width: 12rem;
  align-items: center;
  appearance: none;
//   margin-left: 3.5rem;
//   margin-bottom: 3.5rem;
  transform: translate(20%,55%);
  background-color:#00BFFF;
  border: 1px solid #dbdbdb;
  border-radius: .375em;
  box-shadow: none;
  box-sizing: border-box;
  color: white;
  cursor: pointer;
  display: inline-flex;
  font-family: BlinkMacSystemFont,-apple-system,"Segoe UI",Roboto,Oxygen,Ubuntu,Cantarell,"Fira Sans","Droid Sans","Helvetica Neue",Helvetica,Arial,sans-serif;
  font-size: 2 rem;
  height: 4.5rem;
  justify-content: center;
  line-height: 1.5;
//   padding: calc(.5em - 1px) 1em;
  position: relative;
  text-align: center;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  vertical-align: top;
  white-space: nowrap;
}

button:active {
  border-color: #4a4a4a;
  outline: 0;
}

button:hover {
  border-color: #b5b5b5;
}

