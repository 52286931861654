@import url('https://fonts.googleapis.com/css2?family=Bowlby+One&family=Lora:ital,wght@0,400..700;1,400..700&family=Merriweather:ital,wght@0,300;0,400;0,700;0,900;1,300;1,400;1,700;1,900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Roboto+Slab:wght@100..900&display=swap');

ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
}


.overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.3); 
    backdrop-filter: blur(5px); 
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 3;
}


@mixin addTrucktShrinkLabel {
    top: -14px;
    font-size: 12px;
    color: black;
  }

.addtruck-section{
    width: 100%;
    min-height: 100vh;
    display: flex;
    align-items: center;
    flex-direction: column;
}


.addtruck-button-empty, .addtruck-button{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    background-color: white;
    cursor: pointer;
    border-radius: 10px; 
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    -webkit-user-select: none; 
    -ms-user-select: none; 
    user-select: none; 

    &:hover{
        transform: scale(1.05);
        transition: all .5s;
        
    }

}

.addtruck-button-empty-effect{
    width: 80%;
    height: 80vh;
    margin-top: 100px;
    border-radius: 10px; 
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    overflow: hidden;
}

.addtruck-button-empty{
    width: 100%;
    height: 100%;
    flex-direction: row;

}


.addtruck-button{
    width: 300px;
    height: 300px;
    flex-direction: column;
}

.addtruck-round-center{
    width: 70px;
    height: 70px;
    background-color: #C2C2C2;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 100%;
}

.addtruck-text{
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 32px;
    line-height: 100%;
    color: #C2C2C2;
}


.addtruck-round-center-horizontal-line, .addtruck-round-center-vertical-line {
    width: 30%;
    height: 5px;
    background-color: white;
}

.addtruck-round-center-vertical-line {
    position: relative;
    bottom: 5px;
    transform: rotate(90deg);
}


.addtruck-availabletrucks-container{
    display: flex;
    flex-wrap: wrap; 
    justify-content: center;
    width: 100%;
    max-width: 1500px;
    min-height: 70vh;
    margin-top: 150px;
    gap: 50px;
    margin-bottom: 50px;
}

.addtruck-availabletrucks-card-effect{
    width: 300px;
    height: 300px;
    border-radius: 10px;
    overflow: hidden;
    

    &:active{
        transform: scale(0.97);
    }
}

.addtruck-availabletrucks-card {
    position: relative;
    width: 300px;
    height: 300px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 24px;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    border-radius: 10px; 
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    -webkit-user-select: none; 
    -ms-user-select: none; 
    user-select: none; 
    cursor: pointer;

    &:hover{
        transform: scale(1.05);
        transition: all .5s;
        
    }
  }


  .addtruck-availabletrucks-card-edit{
    
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 0;
    right: 0;
    width: 80px;
    height: 40px;
    font-size: 14px;
    color: white;

    &:hover{
        text-decoration: underline;
    }

  }

  .addtruck-availabletrucks-card-description{

    position: absolute;
    bottom: 0;
    width: 100%;
    height: 90px;
    padding-left: 20px;
    font-size: 14px;
    color: white;

  }


.addtruck-new-form-container {
    width: 70%;
    max-width: 1000px;
    height: 70vh;
    display: flex;
    flex-direction: column;
    margin-top: 150px;
    border-radius: 10px; 
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.addtruck-hide{
    display: none !important;
}



.addtruck-new-form-input-container{
    width: 70%;
    height: 70%;
    display: grid;
    grid-template-columns: repeat(2, 1fr); 
    column-gap: 10em;
    margin: auto;
}




.addtruck-form-input{
    width: 100%;
    height: 50px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 100%;
    border: none;
    border-bottom: 1px solid grey;
    padding-left: 10px;

    &:focus {
        outline: none;
      }


      &:focus ~ .addtruck-form-label {
        @include addTrucktShrinkLabel();
        
      }

      &:not(:focus) ~ .addtruck-form-label {
        transition-delay: 100ms;
        
      }


}





.addtruck-form-label {
    color: grey;
    font-size: 20px;
    font-weight: normal;
    position: absolute;
    pointer-events: none;
    left: 14px;
    top: 10px;
    transition: 300ms ease all;


    &.addtruck-form-shrink {
      @include addTrucktShrinkLabel();
    }
  }



  .addtruck-form-dropdown{
    position: absolute;
    width: 100%;
    height: 200px;
    top: 53px;
    background-color: white;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    border-radius: 5px;
    overflow: scroll;
    z-index: 1;
  }



  .addtruck-form-dropdown-choices{
    width: 90%;
    height: 50px;
    cursor: pointer;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    margin: 5px;
    padding-top: 15px;
    padding-left: 15px;
    border-radius: 5px;

    &:hover{
        
        background-color: grey;
    }
  }


  .addtruck-save-button-container{
    width: 100%;
    height: 20%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 100px;
  }


  .addtruck-save-button{
        width: 20%;
        height: 40%;
  }

  .custom-scroll {
    overflow: auto;
  }

.addtruck-warning-message{
    position: absolute;
    width: 40%;
    height: 200px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    background-color: white;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    border-radius: 10px;
    z-index: 4;

}

.addtruck-warning-button{
    width: 10%;
    height: 20%;
}

.addtruck-warning-button-container{
    width: 250px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
}

.addtruck-warning-button-cancel, .addtruck-warning-button-continue{
    width: 120px;
    height: 100%;
}




  @media only screen and (max-width: 1200px) {
        .addtruck-new-form-container{
            margin-top: 100px;
            height: 800px;
            
        }

        .addtruck-new-form-input-container{
            width: 70%;
            height: 70%;
            display: grid;
            grid-template-columns: repeat(1, 1fr); 
            column-gap: 10em;
            margin-top: 20px;
            margin-bottom: 0;
           
        }

        .addtruck-button-empty{
            flex-direction: column;
        }

        .addtruck-save-button-container{
            flex-direction: column;
            height: 20%;
            gap: 5px;
        }
        .addtruck-save-button{
            width: 40%;
            height: 20%;
        }

        .addtruck-bottom-phoneview{
            width: 100%;
            height: 100px;
        }
        .addtruck-form-label{
            font-size: 14px;
        }
        .addtruck-warning-message{
            width: 80%;
        }
  }







