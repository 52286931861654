.headline-global{
  position: relative;
  width: 100%;
  height: 100vh;
  max-height: 900px;
  display: flex;                  
  align-items: center;         
  gap: 20px;     
  background-color: rgba(191, 191, 191, 0.6);
  overflow: hidden;
  
}

.headline-container{
  width: 100%;
  max-width: 1600px;
  height: 80%;
  max-height: 900px;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}


.headline-text-container{
  width: 50%;
  height: 100%;
  padding-top: 15rem;

}

h1 {
  margin: 0;
  padding: 0;
}

.headline-text-header{
      width: 600px;
      font-family: 'Lora';
      font-style: normal;
      font-weight: 600;
      font-size: 6rem;
      letter-spacing: 0.2rem;
      color: #5C2A00;
      margin-left: 50px;

}

.headline-text-paragraph{
    width: 500px;
    height: 100px;

    font-family: 'Lora';
    font-style: normal;
    font-weight: 400;
    font-size: 2rem;
    line-height: 100%;

    letter-spacing: 3px;
    margin-top: 1rem;
    color: #5C2A00;
    margin-left: 50px;

}


.headline-image{
  margin-right: 50px;
  width: 50%;

}

.headline-join-now-button {
  width: 200px;
  height: 60px;
  margin-top: 2rem;
  margin-left: 50px;
} 



.wave{
  position: absolute;
  bottom:-1%;
  width: 100%;
}



@media only screen and (max-width: 1100px) {
  .headline-container{
    max-height: 2000px;
    flex-direction: column;
  }
  .headline-global{
    height: 2500px;
  }
  .headline-text-container{
    width: 90%;
    height: 400px;
    padding-top: 0;
  
  }

  .headline-text-header{
    width: 100%;
    font-size: 4rem;
    margin-left: 0px;

}
  
  .headline-text-paragraph{
      width: 100%;
      height: auto;
      margin-left: 0px;
      margin-bottom: 15px;
  
  }

  .headline-join-now-button {
    width: 200px;
    height: 60px;
    margin-top: 5px;
    margin-bottom: 5px;
    margin-left: 0px;
  } 

  .headline-image{
    margin-right: 0px;
    width: 80%;
  
  }

  .wave{
    position: absolute;
    bottom:-0.1%;
    width: 100%;
  }
}