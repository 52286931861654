@import url('https://fonts.googleapis.com/css2?family=Bowlby+One&display=swap');

@mixin contactShrinkLabel {
    top: -14px;
    font-size: 12px;
    color: rgba(255, 255, 255, 0.58);
  }



.contact-section{
    width: 100%;
    background-color: #141718;
    overflow: hidden;
}

.contact-section-container{
    width: 100%;
    max-width: 1500px;
    height: 100%;
    margin: auto;

}

.contact-section-backtext{
    font-family: 'Bowlby One';
    font-style: normal;
    font-weight: 400;
    font-size: 128px;
    line-height: 132%;
    color: #141718;
    text-shadow: -1px -1px 0 rgba(255, 255, 255, 0.03), 1px -1px 0 rgba(255, 255, 255, 0.03), -1px 1px 0 rgba(255, 255, 255, 0.03), 1px 1px 0 rgba(255, 255, 255, 0.03);
    letter-spacing: 4px;

}


.contact-form-container{
    display: flex;
    justify-content: space-between;
}

.contact-form-headline{

    font-family: 'Bowlby One';
    font-style: normal;
    font-weight: 400;
    font-size: 46px;
    line-height: 132%;

    display: flex;
    align-items: center;
    letter-spacing: -2px;

    color: rgba(255, 255, 255, 0.81);


}


.contact-form-input-container{
    width: 55%;
    height: 200px;
    display: grid;
    grid-template-columns: repeat(2, 1fr); 
    grid-gap: 20px;
}




.contact-form-input{
    width: 100%;
    height: 50px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 100%;
    color: rgba(255, 255, 255, 0.58);

    background-color: #141718;
    border: none;
    border-bottom: 1px solid #FFFFFF;
    padding-left: 10px;

    &:focus {
        outline: none;
      }
  
      &:focus ~ .contact-form-label {
        @include contactShrinkLabel();
      }


}





.contact-form-label {
    color: rgba(255, 255, 255, 0.58);
    font-size: 26px;
    font-weight: normal;
    position: absolute;
    pointer-events: none;
    left: 20px;
    top: 10px;
    transition: 300ms ease all;

    &.contact-form-shrink {
      @include contactShrinkLabel();
    }
  }
