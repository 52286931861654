.FAQ-line{
    width: 80%; 
    height: 2px; 
    max-width: 1200px;
    margin-top: 150px;
    margin-bottom: 100px;
    margin-left: auto;
    margin-right: auto;
    border: 1px solid #000000;
}

.FAQ-headline{

width: 60%;
height: 36px;
margin-left: auto;
margin-right: auto;
margin-bottom: 150px;

font-family: 'Poppins';
font-style: normal;
font-weight: 900;
font-size: 50px;
line-height: 100%;

display: flex;
justify-content: center;
align-items: center;
text-align: center;
letter-spacing: 1px;
text-transform: capitalize;

color: #001B2A;


}