.goals-section{
    width: 100%;
    height: 210%;
    margin-top: 150px;
    margin-bottom: 50px;
    background: #F2F2F2;
    overflow: hidden;
}



.goals-header{

    width: 100%;
    height: 100px;

    font-family: 'Merriweather';
    font-style: normal;
    font-weight: 900;
    font-size: 48px;
    line-height: 160%;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    letter-spacing: 2px;
    background: linear-gradient(
      to top, 
      #F2F2F2 0%, 
      #F2F2F2 50%, 
      white 50%, 
      white 100%
    );

    color: #484D4E;

}


// styles part 1 of the goals section

.goals-part1-container {
    width: 99%;     
    max-width: 1500px;  
    max-height: 900px;
    margin: auto;
    height: 500px;         
    border-radius: 7px;
    overflow: hidden;
  }


  .content-part1-left {
    width: 650px;
    max-width: 2000px;
    height: auto;
    z-index: 1;
    border-radius: 9px;


    font-family: 'Lora';
    font-style: italic;
    font-weight: 700;
    font-size: 32px;
    line-height: 149%;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;

    color: #1B2232;
  }

  .content-part1-line{
    width: 400px;
    height: 4px;
    background: #1B2232;
    border-radius: 9px;
  }


  .goals-part1-image-right {

    width: 50%;
    aspect-ratio: 16 / 9;
    margin-top: 50px;
    z-index: 0;
    opacity: 0.6;      
    border-radius: 9px;
    float: right;
  }


  // styles part 2 of the goals section

  .goals-part2-container {
    width: 99%;     
    max-width: 1500px;  
    max-height: 900px;
    margin: auto;
    height: 550px;         
    border-radius: 7px;
    overflow: hidden;    
  }

  .goals-part2-float{
    float: right;
    width: auto;
  }

  
  .content-part2-right {
    width: 100%;
    max-width: 2000px;
    height: auto;
    z-index: 1;
    border-radius: 9px;

    font-family: 'Lora';
    font-style: italic;
    font-weight: 700;
    font-size: 32px;
    line-height: 149%;

    color: #1B2232;

  }

  .content-part2-line{
    width: 60%;
    height: 4px;
    background: #1B2232;
    border-radius: 9px;
    float: right;
  }

  .goals-part2-image-left {
    width: 50%;
    aspect-ratio: 16 / 9;
    margin-top: 50px;
    margin-bottom: 50px;
    z-index: 0;
    opacity: 0.6;      
    border-radius: 9px;
  }


