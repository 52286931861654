.datepickteststyle{
    width: 250%;
    height: 70px;
    border-radius: 5px;
}

.calendarstyles{
    .react-datepicker__week:nth-child(3n + 1) {
        width: 300px;
        height: 40px;
      }
      .react-datepicker__week:nth-child(3n + 2) {
        width: 300px;
        height: 40px;
      }
      .react-datepicker__week:nth-child(3n + 3) {
        width: 300px;
        height: 40px;
      }
      .react-datepicker__day {
        width: 25px;
        height: 25px;
        font-size: 15px;
      }
}